import { Link } from "gatsby";
import React from "react";
import ExploreTogether from "../components/explore_together";
import Footer from "../components/footer";
import Header from "../components/header";
import SinglePageHeader from '../components/singlepageheader';
import { SEO } from "../components/seo";

import about1 from '../images/about1.png'
import about2 from '../images/about2.jpeg'
// import avatar from '../images/avatar.jpg'

const AboutUs = () => {

    const quality = [
        {
            title: 'On time Secure & Reliable',
            desc: 'Our priority is to hire the most reliable staff and imbibe qualities such as round the - clock awareness, and technical expertise to maintain advanced security solutions'
        },
        {
            title: 'Trusted Cleaners',
            desc: 'We want to make sure that small details are taken care of at every nook and corner. We ensure their work is of the highest quality by developing an action plan that is previously agreed upon by our client.'
        },
        {
            title: 'Best Quality',
            desc: 'Our dedicated professionals are well organized to complete their work in a rationalized manner and are admired for their seamless efforts and high quality of services'
        }
    ]

    return(
        <div className="container">
            <SEO  title="About Us" />
            <Header />
            <SinglePageHeader page="About Us" />
            <div className="section wework">
				<div className="innerwrap">
					<div className="wework-cols">
						
						<div className="col">
							<div className="description">
								<p className="subhead">Know About Us</p>
								<h1>Established in the year 2019, Pooja Services.</h1>
                                <p>We Pooja Services is one of the eminent service providers for security Guards, Bouncers, Housekeeping Staffs, Manpower Services both Male and Female and similar Services Across Maharashtra. Our Organization was started with an aim to provide safe, reliable, dedicated & efficient means of protecting materials, machines, men and property belonging to any individual/body organization.</p>
								<p>Owing to the unique features these services are widely demanded by varied Bodies including Government Department, Public Sector Undertakings (PSU), Corporate Houses, Housing Societies & Individuals, We are registered with PSARA ( Private Security Agency Regulation Act ) Govt of Maharashtra. We always strive hard to provide top-notch services to our clients as per their requirements within a committed time frame adhering to the same, we have employed a highly experienced and dedicated team of professionals in each of our departments, who are capable to handle all the intricate situation of running business in best effective manner. The Services are at par with the highest industry standards and cater to the requirements of the client adequately & Legal liasoning Services Like SRA, BMC, MHADA, New Development Proposal Submission Related Licensing Work & tenant management.</p>
								
                                <ul>
									<li> <ion-icon name="checkmark-outline"></ion-icon> Experienced Team</li>
									<li> <ion-icon name="checkmark-outline"></ion-icon> Keep the same cleaner for every visit</li>
									<li> <ion-icon name="checkmark-outline"></ion-icon> One-off, weekly or fortnightly visits</li>
									<li> <ion-icon name="checkmark-outline"></ion-icon> Book, manage & pay easily</li>
								</ul>
								<Link to="/our-services" type="button" className="btn">See All Services <ion-icon name="chevron-forward"></ion-icon></Link>
							</div>

						</div>
                        <div className="col">
							<div className="imageholder">
								<div className="img1">
									<img src={about1} alt="Pooja Services Mangement Profile 1" />
								</div>
								<div className="img2">
									<img src={about2} alt="Pooja Services Mangement Profile 2" />
								</div>
							</div>

						</div>
					</div>

				</div>
			</div>
            <div className="section qualities">
                <div className="innerwrap">
                    <div className="section-title">What We Offer</div>
                    <br/>
                    <p>The first direct interaction between a new customer and the company beside the inherent good values.</p>

                    <br/>
                    <div className="qualities-list">
                        {quality.map((item, index) => (
                            <div key={index} className="item">
                                <div className="icon"><ion-icon name="checkmark-sharp"></ion-icon></div>
                                <h2 className="title">{item.title}</h2>
                                <p className="desc">
                                    {item.desc}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* <div className="section ourteam">
                <div className="innerwrap">
                    <div className="section-title">Meet Our Team</div>
                    <div className="section-desc">Ahen an unknown printer took a galley of type and scrambled</div>
                    <div className="ourteamlist">
                        {[1,2,3].map(() => (
                            <div className="item">
                                <div className="avatar">
                                    <img src="https://w7.pngwing.com/pngs/498/98/png-transparent-businessperson-graphy-getty-s-i-businessman-people-public-relations-color.png" />
                                </div>
                                <div className="details">
                                    <h2>Full Name</h2>
                                    <span>Profile Description</span>
                                    <ul className="social">
                                        <li><a href="" className="facebook"><ion-icon name="logo-facebook"></ion-icon></a></li>
                                        <li><a href="" className="linkedin"><ion-icon name="logo-linkedin"></ion-icon></a></li>
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div> */}
            <ExploreTogether />
            <Footer />
        </div>
    )
}

export default AboutUs;