import React from "react";
import bannerfigure from '../images/banner-figure.png'

const ExploreTogether = () => {
    return (
        <div className="section explore">
            <div className="innerwrap">
                <div className="explore-cols">
                    <div className="col description">
                        <h1> We <span> Work </span> For You </h1>
                        <p>We Pooja Services one of the eminent service providers for security Guards, Bouncers, Housekeeping Staff, Manpower Services both Male and Female, and similar Services Across Maharashtra</p>
                        <button type="button" className="btn">Book an Appointment <ion-icon name="chevron-forward"></ion-icon></button>
                    </div>
                    <div className="col imageholder">
                        <img src={bannerfigure} alt="Cleaning Services" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExploreTogether;